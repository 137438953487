/* Общие стили для всего приложения */
.App {
  text-align: center;
}

/* Стили для хедера */
.header {
  background-color: #85b3e5;
  color: #000;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Добавляем это свойство */
}

/* Стили для навигационных ссылок */
.header nav a {
  color: #000;
  text-decoration: none;
  margin: 0 10px;
}

.header nav a:hover {
  text-decoration: underline;
}

.menu-button {
  display: none;
}

/* Стили для основного контента страницы */
.content, .currency-page {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .menu-button {
    display: block;
    cursor: pointer;
    z-index: 1000;
  }

  .nav {
    display: none;
    position: absolute; /* Это уже есть */
    top: 100%; /* Это гарантирует, что меню будет начинаться сразу под кнопкой */
    left: 0;
    width: 100%;
    background-color: #b0e8e8;
    border-top: 1px solid #0a0a0a;
    z-index: 999; /* Меньше, чем у кнопки, чтобы она была поверх меню */
  }

  .nav.show {
    display: block; /* Или flex, если вы хотите использовать flexbox */
  }

  .nav a {
    padding: 10px;
    display: block;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .nav a:last-child {
    border-bottom: none;
  }
}

/* Стили для кнопок */
.fetch-button {
  background-color: #007bff; /* Синий фон кнопки */
  color: #fff; /* Белый текст кнопки */
  border: none; /* Убираем границу */
  padding: 10px 20px; /* Отступы вокруг текста кнопки */
  cursor: pointer; /* Курсор в виде указателя */
  border-radius: 5px; /* Скругление углов кнопки */
}

.fetch-button:hover {
  background-color: #0056b3; /* Темно-синий фон при наведении */
}

/* Стили для контейнера с данными о валютах */
.data-container {
  background-color: #f8f9fa; /* Светло-серый фон */
  border: 1px solid #dee2e6; /* Граница контейнера */
  padding: 15px; /* Отступы внутри контейнера */
  margin-top: 15px; /* Отступ сверху */
}

.currency-rates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.currency-rate-block {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  width: calc(50% - 20px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
